import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import {
  IToggleSwitchProps,
  IToggleSwitchSDK,
  IToggleSwitchImperativeActions,
  IToggleSwitchOwnSDKFactory,
} from '../ToggleSwitch.types';
import {
  composeSanitizers,
  numberToString,
  emptyStringIfNotString,
} from '../../../core/corvid/inputUtils/inputSanitization';
import {
  createElementPropsSDKFactory,
  createValuePropsSdkFactory,
  createCheckedPropsSDKFactory,
  focusPropsSDKFactory,
  disablePropsSDKFactory,
  clickPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';

import { createEmptyInputValidator } from '../../../core/corvid/inputUtils';

const valueSanitizer = composeSanitizers([
  numberToString,
  emptyStringIfNotString,
]);

const valuePropsSDKFactory = createValuePropsSdkFactory<
  IToggleSwitchProps,
  IToggleSwitchImperativeActions
>(
  value => valueSanitizer(value),
  { type: ['string'] },
  createEmptyInputValidator(),
);

const checkedPropsSDKFactory = createCheckedPropsSDKFactory<
  IToggleSwitchProps,
  IToggleSwitchImperativeActions
>(createEmptyInputValidator());

const ownSDKFactory: IToggleSwitchOwnSDKFactory = ({ props, metaData }) => {
  return {
    get type() {
      return '$w.Switch';
    },
    toJSON() {
      const { checked, value } = props;
      return {
        ...toJSONBase(metaData),
        checked,
        value,
        type: '$w.Switch',
      };
    },
  };
};
const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk = composeSDKFactories<IToggleSwitchProps, IToggleSwitchSDK>(
  elementPropsSDKFactory,
  disablePropsSDKFactory,
  focusPropsSDKFactory,
  clickPropsSDKFactory,
  checkedPropsSDKFactory,
  valuePropsSDKFactory,
  ownSDKFactory,
);
